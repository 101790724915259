import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { IAuth } from '../../open-age/core/services/auth.interface';
import { RoleService } from '../../open-age/core/services';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(
    private role: RoleService
  ) { }

  getHeaders() : HttpHeaders {
    const timeStamp =  moment().utcOffset().toString();
    const headers = {
      'Content-Type': 'application/json',
      'If-Modified-Since': timeStamp.toString(),
    };
    let tenantCode = '';
    let orgCode = '';
    if (environment.code) {
      tenantCode = environment.code;
    }
    const role = this.role.currentRole();
    const organization = this.role.currentOrganization();
    const tenant = this.role.currentTenant();
    const session = this.role.currentSession();
    if (session && session.id) {
      headers['x-session-id'] = session.id;
    }

    if (role && role.token) {
      headers['authorization'] = role.token;
    }

    if (organization && organization.code) {
      orgCode = organization.code;
    }
    if (orgCode) {
      headers['x-organization-code'] = orgCode;
    }
    if (tenantCode) {
      headers['x-tenant-code'] = tenantCode;
    }
    return new HttpHeaders(headers);
  }
}
