export const environment = {
  // enviroment = tenant
  production: true,
  name: 'prod',

  code: null,
  host: 'api.telepain.md', // this is only for local debugging

  // environment details would be fetched from here
  source: 'https://api.telepain.md/directory/api/tenants',

  organization: { code: null },
  loginTypes: ['email'],
  cacheDuration: 50, // minutes

  // if services section exists then it would be used
  services: [],

  // if navs section exists then it would be used
  navs: [],
};

