import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { RoleService } from '../../open-age/core/services';


export const timeZonesList = [
  {
    name: 'Atlantic',
    timeZone: 'America/Halifax',
    offset: -180, // UTC-3
    displayName: 'Atlantic',
  },
  {
    name: 'Eastern',
    timeZone: 'America/New_York',
    offset: -240, // UTC-4
    displayName: 'Eastern',
  },
  {
    name: 'Central',
    timeZone: 'America/Chicago',
    offset: -300, // UTC-5
    displayName: 'Central',
  },
  {
    name: 'Mountain',
    timeZone: 'America/Denver',
    offset: -360, // UTC-6
    displayName: 'Mountain',
  },
  {
    name: 'Pacific',
    timeZone: 'America/Los_Angeles',
    offset: -420, // UTC-7
    displayName: 'Pacific',
  },
  {
    name: 'Alaska',
    timeZone: 'America/Anchorage',
    offset: -480, // UTC-8
    displayName: 'Alaska',
  },
  {
    name: 'Hawaii Aleutian',
    timeZone: 'Pacific/Honolulu',
    offset: -600, // UTC-10
    displayName: 'Hawaii Aleutian',
  },
  {
    name: 'Arizona',
    timeZone: 'America/Phoenix',
    offset: -540, // UTC-7 due to already UTC-7 is there so we are hardcoded some value for identification
    displayName: 'Arizona',
  }
];

export interface runPatientReport {
  id: number | string,
  isConfirmed: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TelepainService {
  private _microService = 'telepain';
  PATIENT_BAMBOO_REPORT = 'patient-bamboo-report';
  BAMBOO_REPORT_DETAIL = 'patient-bamboo-report/getPDMPReport';
  url;
  constructor(
    private api: ApiService,
    private role: RoleService,
    private http: HttpClient
  ) {
    if (!this.url) {
      this.getMicroServiceURL();
    }
  }

  getMicroServiceURL() {
    const tenant = this.role.currentTenant();
    if (tenant && tenant.services) {
      const microServiceDet: any = tenant.services.find((service: any) => service.code == this._microService);
      if (microServiceDet.url !== undefined) {
        this.url = microServiceDet.url + '/';
      } else {
        this.url = '';
      }
    }
  }

  getBambooReportURL(patientID: string) {
    return this.api.get(`${this.url}${this.PATIENT_BAMBOO_REPORT}/${patientID}`);
  }

  runPatientReport(req: runPatientReport) {
    return this.api.post(`${this.url}${this.PATIENT_BAMBOO_REPORT}`, req);
  }

  getBambooReportDetail(reportID: string) {
    return this.api.post(`${this.url}${this.BAMBOO_REPORT_DETAIL}/${reportID}`, {});
  }

  getJSONData() {
    return this.http.get('assets/files/bamboo.json')
  }


}
